/* eslint-disable no-restricted-globals */
"use client";

import styles from "./styles.module.scss";
import Logo from "../../images/laproLogo.png";
import { useState, useEffect } from "react";
import classNames from "classnames";
import { BiSolidUserCircle } from "react-icons/bi";
import { ContactIcons } from "../contactIcons/contactIcons.jsx";
import Hamburger from "hamburger-react";
import Button from "../Button";
import { logout } from "../../api/allauth";
import { Navigate } from "react-router-dom";
import { URLs } from "../../api/routing";
import { styled } from "@mui/material/styles";
import { red } from "@mui/material/colors";

export const LoginHeader = ({ authed, user }) => {
  const [isHeaderBig, setBigHeader] = useState(false);
  const [redirect, setRedirect] = useState(false);
  /* const [currentIndex, setCurrentIndex] = useState(); */
  const [coloredHeader, setColoredHeader] = useState(false);

  // const menu = [
  //     {
  //         text: "О нас",
  //         link: "#about",
  //     },
  //     {
  //         text: "Продукты",
  //         link: "#products",
  //     },
  //     {
  //         text: "Приложение",
  //         link: "#application",
  //     },
  //     {
  //         text: "Контакты",
  //         link: "#contacts",
  //     },
  // ]
  useEffect(() => {
    const listener = () => {
      if (window.scrollY > 140) {
        setColoredHeader(true);
      } else setColoredHeader(false);
    };
    window.addEventListener("scroll", listener);

    return () => {
      window.removeEventListener("scroll", listener);
    };
  }, []);

  const logoutFunct = () => {
    logout()
      .then((content) => {
        setRedirect(true);
      })
      .catch((e) => {
        console.error(e);
        window.alert(e);
        setRedirect(false);
      });
  };
  if (redirect) {
    return <Navigate to={URLs.LOGIN_URL} />;
  }

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  }));

  return (
    <>
      <header
        className={classNames(
          styles.header,
          !coloredHeader ? "" : styles.headerDark,
          location.pathname !== "/" ? styles.headerAnotherPage : ""
        )}
      >
        <div className={styles.logoHolder}>
          <img src={Logo} alt={"laprobox logo"} width={274} height={80} />
        </div>
        <div className={styles.menuHolder}>
          <a href="/" className={styles.itemText}>
            Главная
          </a>
          {authed && (
            <ColorButton
              className={styles.button}
              variant="contained"
              onClick={logoutFunct}
            >
              Выйти
            </ColorButton>
          )}
          {authed && user?.data?.user?.username}
          {/* <div className={styles.menu}>
                            {
                                menu.map((item, index)=>{
                                    return(
                                        <div className={styles.item}>
                                            <Link href={item.link} onClick={() => setCurrentIndex(index)} className={classNames(styles.itemText, currentIndex === index ? styles.itemTextActive : '')}>{item.text}</Link>
                                            
                                        </div>
                                    )
                                    
                                })
                                
                            }
                            
                        </div> */}
          <ContactIcons />
          <a className={styles.btn} href="/login">
            <BiSolidUserCircle className={styles.login} fill="#781f19" />
          </a>
        </div>
      </header>

      <div className={styles.mobileHeader}>
        <Hamburger
          toggled={isHeaderBig}
          onToggle={() => setBigHeader(!isHeaderBig)}
          color="black"
        />
        <div
          className={classNames(
            styles.small,
            `${isHeaderBig ? styles.big : ""}`
          )}
        >
          {/* <div className={styles.menuMobile}>
                                {
                                    menu.map((item, index)=>{
                                        return(
                                        
                                            <div className={styles.item}>
                                                <Link href={item.link} onClick={() => setCurrentIndex(index)} className={classNames(styles.itemText, currentIndex === index ? styles.itemTextActive : '')}>{item.text}</Link>
                                                
                                            </div>
                                        )
                                        
                                    })
                                    
                                }
                                
                            </div> */}
          <div className={styles.iconsContainer}>
            <ContactIcons />
            <a href="/loginPage">
              <BiSolidUserCircle className={styles.login} fill="#781f19" />
            </a>
          </div>
          <div className={styles.logoHolder}>
            <img src={Logo} alt={"laprobox logo"} width={274} height={80} />
          </div>
        </div>
      </div>
    </>
  );
};
