import styles from './styles.module.scss';
import LaproPhoto1 from '../../images/laproPhoto1.jpg';
import LaproPhoto2 from '../../images/laproPhoto2.jpg';
import LaproPhoto3 from '../../images/laproPhoto3.jpg';

export const Photos = () => {
    return(
        <div className={styles.photos} id='photos'>
            
            <img src={LaproPhoto1} className={styles.imgBig}  alt='обучение лапароскопии'/>
            <div className={styles.vertical}>
                <img src={LaproPhoto2} className={styles.imgSmall} alt='для лапароскопии'/>
                <img src={LaproPhoto3} className={styles.imgSmall} alt='для лапароскопии'/>
            </div>
           
        </div>
    )
}