import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LoginPage from "./pages/loginPage/loginPage";
import Home from "./pages/Home/Home";
import ErrorPage from "./pages/ErrorPage";
import AuthorisedPage from "./pages/authorizedPage/authtorizedPage";
import PersonalDataPage from "./pages/authorizedPage/personalData/personalDataPage";
import InstrumentsPage from "./pages/InstrumentsPage/InstrumentsPage";
import BoxPage from "./pages/BoxPage/BoxPage";
import TrainersPage from "./pages/TrainersPage/TrainersPage";
import ProviderCallback from "./components/ProviderCallback/ProviderCallback";
import Confirm from "./pages/Confirm/confirm";
import VerifyEmail, {
  loader as verifyEmailLoader,
} from "../src/components/VerifyEmail/VerifyEmail";
import ChangeEmail from "./components/ChangeEmail/ChangeEmail";
import ChangePassword from "./components/ChangePassword/ChangePassword";
import ProviderSignup from "./components/ProviderSignup/ProviderSignup";
import ManageProviders from "./components/ManageProvider/ManageProviders";
import RedirectAfterConfirm from "./components/RedirectAfterConfirm/RedirectAfterConfirm";
import RequestPasswordReset from "./components/ResetPassword/RequestPasswordReset";
import { AnonymousRoute, AuthenticatedRoute } from "./api/routing";
import ResetPassword, { loader as resetPasswordLoader } from "./components/ResetPass/ResetPassword";

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      /* {
        path: '/login',
        element: <LoginPage />,
      } */
    ],
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/authorizedPage",
    element: <AuthorisedPage />,
  },
  {
    path: "/personalDataPage",
    element: <PersonalDataPage />,
  },
  {
    path: "/instrumentsPage",
    element: <InstrumentsPage />,
  },
  {
    path: "/boxPage",
    element: <BoxPage />,
  },
  {
    path: "/trainersPage",
    element: <TrainersPage />,
  },
  {
    path: "/confirm",
    element: <Confirm />,
  },
  {
    path: "/account/verify-email/:key",
    element: <VerifyEmail />,
    loader: verifyEmailLoader,
  },
  {
    path: "/accounts/login",
    element: <RedirectAfterConfirm />,
  },
  {
    path: "/account/password/reset/",
    element: <RequestPasswordReset />,
  },
  {
    path: "/account/email",
    element: (
        <ChangeEmail />
    ),
  },
  {
    path: '/account/password/reset/key/:key',
    element: <ResetPassword />,
    loader: resetPasswordLoader
  },
  {
    path: '/account/password/change',
    element: <ChangePassword /> /* </AuthenticatedRoute>  */
  },
  {
    path: "/account/provider/callback",
    element: <ProviderCallback />,
  },
  {
    path: '/account/provider/signup',
    element: <ProviderSignup />
  },
  {
    path: '/account/providers',
    element: <ManageProviders />
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
