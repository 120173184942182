import { BoxHolder } from '../../components/boxHolder/boxHolder';
import { Connection } from '../../components/connection/connection.jsx';
import styles from './styles.module.scss';

const boxPage = () => {

    return(
        <div className={styles.productsContainer}>
            <BoxHolder/>
            <Connection/>
        </div>
    )
}
export default boxPage;