import styles from './styles.module.scss';
import Pro from '../../images/pro.png';
import Light from '../../images/light.png';
import { Product } from '../productComponent/product.jsx';
import { useParams, useNavigate} from "react-router-dom"

export const BoxHolder = () => {
    // const params = useParams()
   
    // const navigate = useNavigate()

    // const chooseFact = (id) => {
    //     navigate(`/boxPage/${parseInt(id)}/0`)             
    // } 

    const products = [
        {
            id: 1,
            image: Pro,
            title: 'Версия ПРО',
            text: 'Конструкция лапароскопического бокса в версии "ПРО" от LAPROBOXMED',
            price: '15999 р',
            info: 'Комплектация подразумевает встроенный источник света, что обеспечивает мобильность установки. Порты для инструментов расположены на оптимальной высоте и расстоянии друг от друга, что позволяет им двигаться по естественной траектории, максимально имитирующей настоящие операции. Версия ПРО включает 3 уровня упражнений, каждый уровень содержит по 5 тренажеров, работа с которыми направлена на развитие моторики и контроля движения рук. С помощью ПРО версии можно освоить базовые навыки лапароскопии: правильное движение рук, сочетанная работа рук и глаз, ориентирование в рабочей зоне с помощью камеры, а также набор позволяет отточить навыки лапароскопических операций.',
        },
        {
            id: 2,
            image: Light,
            title: 'Версия ЛАЙТ',
            text: 'Конструкция лапароскопического бокса в версии "ЛАЙТ" от LAPROBOXMED',
            price: '12999 р',
            info: 'Складной формат бокса позволяет использовать его на минимальной рабочей зоне. Комплектация подразумевает встроенный источник света, что обеспечивает большую мобильность установки. Порты для инструментов расположены в определенных местах, что позволяет достичь движение инструментов по естественной траектории, максимально имитирующей настоящие операции. Версия ЛАЙТ включает 3 уровня упражнений, каждый уровень содержит по 3 тренажера, работа с которыми направлена на развитие моторики и контроля движения рук. С помощью ЛАЙТ версии можно освоить базовые навыки лапароскопии: правильное движение рук, сочетанная работа рук и глаз, ориентирование в рабочей зоне с помощью камеры.',
        },
    ]

return(
    <div className={styles.productsHolder}>
        <a href='/'><button className={styles.button}>На главную страницу</button></a>
        <h2 className={styles.title}>Учебные боксы</h2>
        <div className={styles.products}>
            {products.map(product =>{

                return  <Product product={product} />
            })
            }
        </div>
    </div>
)

}