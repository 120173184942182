import { TrainersHolder } from '../../components/trainersHolder/trainersHolder';
import { Connection } from '../../components/connection/connection.jsx';
import styles from './styles.module.scss';

const trainersPage = () => {
    return(
        <div className={styles.productsContainer}>
            <TrainersHolder/>
            <Connection/>
        </div>
    )
}
export default trainersPage;