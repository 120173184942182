import { InstrumentsHolder } from '../../components/instrumentsHolder/instrumentsHolder.jsx';
import { Connection } from '../../components/connection/connection.jsx';
import styles from './styles.module.scss';

const instrumentsPage = () => {
    return(
        <div className={styles.productsContainer}>
            <InstrumentsHolder/>
            <Connection/>
        </div>
    )
}
export default instrumentsPage;