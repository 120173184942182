import { Navigate, Link } from "react-router-dom";
import { URLs } from "../../api/routing";
import { getSessions } from "../../api/allauth";
import { useEffect, useState } from "react";

export default function ProviderCallback() {
  /* const [auth, status] = useAuthStatus(); */
  const [session, setSessions] = useState(undefined);
  const [error, setError] = useState(false);

  useEffect(()=>{
    getSessions().then((resp) => {
      if (resp.status === 200) {
        setSessions(resp.data);
        if (!resp.data[0].is_current) {
          // eslint-disable-next-line no-self-assign, no-unused-vars
          setError(true);
        }
      }
    });
  },[session]);

  if (!error) {
    return <Navigate to={URLs.LOGIN_REDIRECT_URL} />;
  }
  return (
    <>
      <h1>Third-Party Login Failure</h1>
      <p>Something went wrong.</p>
      <Link to={URLs.LOGIN_URL}>Continue</Link>
    </>
  );
}
