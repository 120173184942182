import styles from './styles.module.scss';

export const About = () => {
    return(
        <div className={styles.textHolder} id='about'>
            <h3 className={styles.text}>Вы хотите получить или усовершенствовать свои навыки лапароскопической хирургии? Тогда есть только одно решение: практика! C учебными наборами <strong>LAPROBOX</strong><strong className={styles.red}>MED</strong> и нашими тренажерами вы можете практиковаться в любое время и в любом месте.</h3>
            <h3 className={styles.text}>Даже короткие тренировки на <strong>LAPROBOX</strong><strong className={styles.red}>MED</strong> могут принести положительные результаты. И вам не придется беспокоиться о расходах, поскольку наши тренажеры и инструменты доступны по цене и включают в себя бесплатное приложение с тренировками.</h3>
        
        </div>
    )
}