import styles from './styles.module.scss';
import { ContactIcons } from '../contactIcons/contactIcons.jsx';

export const Connection = () => {
    return(
        <div className={styles.details}>
                    <div><a href='mailto: osokinamary28@gmail.com' className={styles.link}>E-mail: osokinamary28@gmail.com</a></div> 
                    <div>
                        <a href='tel: +7 (963) 533 7037' className={styles.link}>Телефон: +7 (963) 533 7037 </a>
                    </div>
                    
                    <p className={styles.text}>По любым вопросам и для заказа свяжитесь с нами по телефону, почте и в соцсетях.</p>
                    <ContactIcons />
            </div>
    )
}