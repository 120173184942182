/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import styles from './styles.module.scss';

export const Person = () => {
    return(
        <div className={styles.person}>
            <img className={styles.avatar}/>
            <a href='/personalDataPage' className={styles.text}>Редактирование профиля</a>
            <a href='' className={styles.text}>Настройка аккаунта</a>
            <a href=''className={styles.text}>Заказы</a>
            <a href='' className={styles.text}>Курсы</a>
        </div>
    )
}