"use client";
import styles from "./styles.module.scss";
import { LoginHeader } from "../../components/loginHeader/loginHeader.jsx";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import { useState } from "react";
import classNames from "classnames";
import { RegistrationIcons } from "../../components/registrationIcons/registrationIcons.jsx";
import { useNavigate } from "react-router-dom";
import { getCSRFToken, getCookie1 } from "../../api/django";

const LoginPage = () => {
  const [registrationVisible, setRegistrationVisible] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isRegistration, setIsRegistration] = useState(false);
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  }));

  getCookie1();

  const Submit = () => {
    // eslint-disable-next-line no-restricted-globals
    event.preventDefault();

    const data = {
      email: username.target.value,
      password: password.target.value,
    };
    setError(null);
    fetch("/_allauth/browser/v1/auth/login", {
      method: "POST",
      headers: {
        Authorization: "Basic base64codedloginadpassword==",
        "Content-Type": "application/json",
        "X-CSRFToken": getCSRFToken(),
      },
      body: JSON.stringify(data),
      mode: "cors",
    })
      .then((response) => {
        if (!response.ok) {
          const respJson = response.json();
          respJson
            .then((res) => {
              if (res.status !== 200) {
                setError(res.errors);
              } else {
                setError(null);

                return navigate("/authorizedPage");
              }
            })
            .catch((e) => {
              setError(e.errors);

              throw Error(e.status);
            });
        } else {
          return navigate("/authorizedPage");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const sendRegistr = () => {
    // eslint-disable-next-line no-restricted-globals
    event.preventDefault();

    const data = {
      email: username.target.value,
      password: password.target.value,
    };
    fetch("/_allauth/browser/v1/auth/signup", {
      method: "POST",
      headers: {
        Authorization: "Basic base64codedloginadpassword==",
        "Content-Type": "application/json",
        "X-CSRFToken": getCSRFToken(),
      },
      body: JSON.stringify(data),
      mode: "cors",
    }).then((response) => {
      if (!response.ok) {
        const respJson = response.json();
        respJson.then((response) => {
          if (response.status !== 200) {
            setError(response.errors);
          }
          if (response.data?.flows[5]?.is_pending === true) {
            navigate("/confirm");
          } else {
            throw Error(response.status);
          }
        });
      }
    });
  };

  return (
    <section className={styles.loginPage}>
      <LoginHeader />
      <div className={styles.page}>
        {!isRegistration && (
          <div
            className={classNames(
              styles.login,
              `${registrationVisible ? styles.loginInVisible : ""}`
            )}
          >
            <p className={styles.title}>Войти</p>
            <form method="POST" action="{% /authorizedPage  %}">
              <div className={styles.form}>
                <div className={styles.item}>
                  <label>
                    Логин или адрес электронной почты
                    <strong className={styles.red}>*</strong>
                  </label>
                  <TextField
                    id="filled-basic"
                    label="Логин"
                    variant="filled"
                    value={username.value}
                    onChange={(e) => setUsername(e)}
                  />
                </div>
                <div className={styles.item}>
                  <label>
                    Пароль<strong className={styles.red}>*</strong>
                  </label>
                  <TextField
                    type="password"
                    id="filled-basic"
                    label="Пароль"
                    variant="filled"
                    value={password.value}
                    onChange={(e) => setPassword(e)}
                  />
                </div>
                {error && (
                  <div className={styles.errorbar}>{error[0]?.message}</div>
                )}
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: red[500],
                          "&.Mui-checked": {
                            color: red[700],
                          },
                        }}
                      />
                    }
                    label="Запомнить"
                  />
                </FormGroup>
                {/* <a href='/authorizedPage'> */}{" "}
                <ColorButton
                  disabled={
                    !(
                      !!password.target?.value.length &&
                      !!username.target?.value.length
                    )
                  }
                  variant="contained"
                  onClick={Submit}
                >
                  Войти
                </ColorButton>
                {/* </a> */}
                <RegistrationIcons />
                <p
                  className={styles.titleInVisible}
                  onClick={() => setRegistrationVisible(true)}
                >
                  Зарегистрироваться
                </p>
              </div>
            </form>
          </div>
        )}

        {isRegistration && (
          <div
            className={classNames(
              styles.register,
              `${registrationVisible ? styles.registerVisible : ""}`
            )}
          >
            <p className={styles.title}>Зарегистрироваться</p>
            <form method="POST" action="{% /authorizedPage  %}">
              <div className={styles.form}>
                <div className={styles.item}>
                  <label>
                    Адрес электронной почты
                    <strong className={styles.red}>*</strong>
                  </label>
                  <TextField
                    id="filled-basic"
                    label="Электронная почта"
                    variant="filled"
                    value={username.value}
                    onChange={(e) => setUsername(e)}
                  />
                </div>
                <div className={styles.item}>
                  <label>
                    Пароль<strong className={styles.red}>*</strong>
                  </label>
                  <TextField
                    type="password"
                    value={password.value}
                    onChange={(e) => setPassword(e)}
                    id="filled-basic"
                    label="Пароль"
                    variant="filled"
                  />
                </div>
                {error && (
                  <div className={styles.errorbar}>{error[0]?.message}</div>
                )}
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: red[500],
                          "&.Mui-checked": {
                            color: red[700],
                          },
                        }}
                      />
                    }
                    onChange={() => setChecked(!checked)}
                    value={checked}
                    label="Принимаю условия пользовательского соглашения"
                  />
                </FormGroup>
                <ColorButton
                  disabled={
                    !checked ||
                    password.target.value.length === 0 ||
                    username.target.value.length === 0
                  }
                  onClick={sendRegistr}
                  variant="contained"
                >
                  Зарегистрироваться
                </ColorButton>
              </div>
            </form>
            <RegistrationIcons />
          </div>
        )}
        <Button
          variant="outlined"
          sx={{
            color: red[400] + "!important",
            marginTop: "4rem",
            border: "1px solid red !important",
            textTransform: "none !important",
          }}
          onClick={() => setIsRegistration(!isRegistration)}
        >
          {isRegistration ? "Уже зарегистрированны?" : "Зарегистрироваться"}
        </Button>
      </div>
    </section>
  );
};
export default LoginPage;
