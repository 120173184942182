
import styles from './styles.module.scss';
import classNames from 'classnames'; 

export const BoxNumber = ({ isInVisible, onClose}) => {
  
    return(
        // <div className={styles.boxNumber} >
        <div className={classNames(styles.boxNumber, isInVisible? styles.boxNumberInVisible : '')} >
            <span className={styles.text}>Введите серийный номер вашего лапробокса </span>
            <div className={styles.enterField}>
                <input type="text" />
                <button className={styles.button} onClick={onClose}>Отправить</button>
            </div>
            
            <p className={styles.explanation}>Регистарция номера лапрабокса необходима для подбора наиболее подходящей для вашей модели упражений</p>
            <button className={styles.button} onClick={onClose}>Пропустить</button>
        </div>
    )
}