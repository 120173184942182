import styles from "./styles.module.scss";
import { useState, useEffect } from 'react'
import * as allauth from '../../api/allauth'
import FormErrors from '../FormErrors'
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { red } from "@mui/material/colors";

export default function ChangeEmail () {
  
  const [email, setEmail] = useState('')
  const [emailAddresses, setEmailAddresses] = useState([])
  const [response, setResponse] = useState({ fetching: false, content: { status: 200, data: [] } })
  
  useEffect(() => {
    setResponse((r) => { return { ...r, fetching: true } })
    allauth.getEmailAddresses().then((resp) => {
      if (resp.status === 200) {
        setEmailAddresses(resp.data)
      }
    }).then(() => {
      setResponse((r) => { return { ...r, fetching: false } })
    })
  }, [])

  function addEmail () {
    setResponse({ ...response, fetching: true })
    allauth.addEmail(email).then((resp) => {
      setResponse((r) => { return { ...r, content: resp } })
      if (resp.status === 200) {
        setEmailAddresses(resp.data)
        setEmail('')
      }
    }).catch((e) => {
      console.error(e)
      window.alert(e)
    }).then(() => {
      setResponse((r) => { return { ...r, fetching: false } })
    })
  }

  function requestEmailVerification (email) {
    setResponse({ ...response, fetching: true })
    allauth.requestEmailVerification(email).catch((e) => {
      console.error(e)
      window.alert(e)
    }).then(() => {
      setResponse((r) => { return { ...r, fetching: false } })
    })
  }

  function deleteEmail (email) {
    setResponse({ ...response, fetching: true })
    allauth.deleteEmail(email).then((resp) => {
      setResponse((r) => { return { ...r, content: resp } })
      if (resp.status === 200) {
        setEmailAddresses(resp.data)
      }
    }).catch((e) => {
      console.error(e)
      window.alert(e)
    }).then(() => {
      setResponse((r) => { return { ...r, fetching: false } })
    })
  }

  function markAsPrimary (email) {
    setResponse({ ...response, fetching: true })
    allauth.markEmailAsPrimary(email).then((resp) => {
      setResponse((r) => { return { ...r, content: resp } })
      if (resp.status === 200) {
        setEmailAddresses(resp.data)
      }
    }).catch((e) => {
      console.error(e)
      window.alert(e)
    }).then(() => {
      setResponse((r) => { return { ...r, fetching: false } })
    })
  }

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  }));
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>Изменить электронную почту</h1>

      <table>
        <thead>
          <tr>
            <th>Эл. почта</th>
            <th>Проверено</th>
            <th>Основные</th>
            <th>Действия</th>
          </tr>
        </thead>
        <tbody>
          {emailAddresses.map(ea => {
            return (
              <tr key={ea.email}>
                <td>{ea.email}</td>
                <td>{ea.verified
                  ? '✅'
                  : '❌'}
                </td>
                <td>
                  <input onChange={() => markAsPrimary(ea.email)} type='radio' checked={ea.primary} />
                </td>
                <td>
                  {ea.verified ? '' : <Button onClick={() => requestEmailVerification(ea.email)} disabled={response.fetching}>Отправить ещё раз</Button>}
                  {ea.primary ? '' : <Button onClick={() => deleteEmail(ea.email)} disabled={response.fetching}>Удалить</Button>}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>

      

      <FormErrors errors={response.content.errors} />

      <div className={styles.inputWrapper}>
      <h2 className={styles.formTitle}>Введите адрес эл. почты</h2>
        <TextField value={email} onChange={(e) => setEmail(e.target.value)} type='email' required id="filled-basic" label="Адрес эл. почты" variant="filled"></TextField>
        <FormErrors param='email' errors={response.content?.errors} />
      </div>
      <ColorButton className={styles.button} variant="contained" disabled={response.fetching} onClick={() => addEmail()}>Добавить</ColorButton>

    </div>
  )
}
