import { useState } from 'react'
import {
  useLoaderData,
  Navigate
} from 'react-router-dom'
import { getEmailVerification, verifyEmail } from '../../api/allauth'
import Button from '../Button'
import styles from "./styles.module.scss";

export async function loader ({ params }) {
  const key = params.key
  const resp = await getEmailVerification(key)
  return { key, verification: resp }
}

export default function VerifyEmail () {
  const { key, verification } = useLoaderData()
  const [response, setResponse] = useState({ fetching: false, content: null })

  function submit () {
    setResponse({ ...response, fetching: true })
    verifyEmail(key).then((content) => {
      setResponse((r) => { return { ...r, content } })
    }).catch((e) => {
      console.error(e)
      window.alert(e)
    }).then(() => {
      setResponse((r) => { return { ...r, fetching: false } });
      <Navigate to='/login' />
    })
  }

  if ([200, 401].includes(response.content?.status)) {
    return <Navigate to='/account/email' />
  }

  let body = null
  if (verification.status === 200) {
    body = (
      <>
        <p>Вы подтверждаете, что эл. почта <a href={'mailto:' + verification.data.email}>{verification.data.email}</a> принадлежит пользователю {verification.data.user.str}.</p>
        <Button disabled={response.fetching} onClick={() => submit()}>Подтверждаю</Button>
      </>
    )
  } else if (!verification.data?.email) {
    body = <p>Укажите правильную ссылку для подтверждения.</p>
  } else {
    body = <p>Эл. почта<a href={'mailto:' + verification.data.email}>{verification.data.email}</a> уже подтверждена. </p>
  }
  return (
    <div className='wrapper'>
      <h1>Подтвердите адрес электронной почты</h1>
      {body}
    </div>
  )
}
