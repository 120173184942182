import styles from './styles.module.scss';



export const Product = ({product}) => {
    
    return(
        <div className={styles.productContainer}>
            <img src={product.image} alt='лапробокс' className={styles.img}/>
            <h3 className={styles.title}>{product.title}</h3>
            <p className={styles.text}>{product.text}</p>
            <p classname={styles.price}>{product.price}</p>
        </div>
    )
   
    
}