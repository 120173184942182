import styles from './styles.module.scss';
import Tr1 from '../../images/Tr1-1.jpeg';
import Tr2 from '../../images/Tr2-1.jpeg';
import Tr3 from '../../images/Tr3-1.jpeg';
import Tr4 from '../../images/Tr4-1.jpeg';
import Tr5 from '../../images/Tr5-1.jpeg';
import { Product } from '../productComponent/product.jsx';

export const TrainersHolder = () => {
        const products = [
            {
                image: Tr1,
                title: 'ТРЕНАЖЕР 1',
                text: '',
                price: '',
            },
            {
                image: Tr2,
                title: 'ТРЕНАЖЕР 2',
                text: '',
                price: '',
            },
            {
                image: Tr3,
                title: 'ТРЕНАЖЕР 3',
                text: '',
                price: '',
            },
            {
                image: Tr4,
                title: 'ТРЕНАЖЕР 4',
                text: '',
                price: '',
            },
            {
                image: Tr5,
                title: 'Силиконовые коврики',
                text: '',
                price: '',
            },
        ]
   
    return(
        <div className={styles.productsHolder}>
            <a href='/'><button className={styles.button}>На главную страницу</button></a>
            <h2 className={styles.title}>ЛАПАРОСКОПИЧЕСКИЕ ИНСТРУМЕНТЫ</h2>
            <div className={styles.products}>
                {products.map(product =>{

                    return  <Product product={product} />
                })
                }
            </div>
        </div>
    )
    
}