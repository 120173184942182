'use client';
import styles from './styles.module.scss';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';


export const MapWrap = () => {
  const placeMark = {
    geometry: [56.471273, 84.944154],
    properties: {
        balloonContent: `<b>Телефон:</b> +7 123 456 78 90<br/><b>Почта:</b> belyakov.gy@ssmu.ru`
    },
   
}
  return (
   
      <div className={styles.Map}>
        <YMaps>
            <Map className={styles.mapImg} state={{ center: [56.471273, 84.944154], zoom: 17 }}>
                <Placemark {...placeMark}  modules={['geoObject.addon.balloon']} />
            </Map>
        </YMaps>
      </div>
   
  );
};
// width={'500px'} height={'500px'} 