
import styles from './styles.module.scss';
import classNames from 'classnames'; 

export const Popover = ({children, isInVisible, x, y}) => {
    
    
    return(
        <div className={classNames(styles.popover, isInVisible?styles.popoverInVisible : '')}>
            <div className={styles.popoverContent}>{children}</div>
            </div>
       
    )
   
}