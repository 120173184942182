/* eslint-disable jsx-a11y/alt-text */
import styles from "./styles.module.scss";
import LaproDevices from "../../images/laproDevices.jpg";
import LaproInstruments from "../../images/laproInstruments.png";
import LaproTrainers from "../../images/laproTrainers.png";


export const Products = () => {
  return (
    <div className={styles.container} id="products">
      <h3 className={styles.title}>Наши продукты</h3>
      <div className={styles.products}>
        <div className={styles.product}>
          <div className={styles.gray}></div>
          <img
            src={LaproDevices}
            className={styles.img}
            layout="fill"
            objectFit="cover"
          />
          <a href='/boxPage'><h3 className={styles.text}>Учебные боксы</h3></a>
        </div>
        <div className={styles.product}>
          <div className={styles.gray}></div>
          <img
            src={LaproInstruments}
            className={styles.img}
            layout="fill"
            objectFit="cover"
          />
          <a href='/instrumentsPage'><h3 className={styles.text}>Лапороскопические инструменты</h3></a>
        </div>
        <div className={styles.product}>
          <div className={styles.gray}></div>
          <img
            src={LaproTrainers}
            className={styles.img}
            layout="fill"
            objectFit="cover"
          />
          <a href='/trainersPage'><h3 className={styles.text}>Тренажеры</h3></a>
        </div>
      </div>
    </div>
  );
};
