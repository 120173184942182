import styles from './styles.module.scss';
import AppImg from '../../images/appImg.png';
import Svg1 from '../../images/1.svg';
import Svg2 from '../../images/2.svg';
import Svg3 from '../../images/3.svg';

export const Application = () => {
    return(
        <div className={styles.application} id='application'>
            <h3 className={styles.title}>БЕСПЛАТНОЕ ВЕБ-ПРИЛОЖЕНИЕ ДЛЯ ОБУЧЕНИЯ</h3>
            <div className={styles.container}>
                <img src={AppImg} className={styles.appImg}  alt=''/> 

                <div className={styles.holder}>
                    <div className={styles.point}>
                        <img src={Svg1} alt='' width={50} height={50}/>
                        <div className={styles.descriptionHolder}>
                            <h3 className={styles.subtitle}>Обучение</h3> 
                            <p className={styles.text}>В <strong>бесплатном веб-приложении</strong> для обучения вы можете найти видеоклипы с нашими практическими упражнениями и бросить себе вызов</p>
                        </div>
                        
                    </div>

                    <div className={styles.point}>
                        <img src={Svg2} alt='' width={50} height={50}/>
                        <div className={styles.descriptionHolder}>
                            <h3 className={styles.subtitle}>Прогресс</h3> 
                            <p className={styles.text}><strong>Анализируйте свои результаты: </strong> в записи вы всегда можете оценить технику и время выполнения манипуляций</p>
                        </div>
                        
                    </div>

                    <div className={styles.point}>
                        <img src={Svg3} alt='' width={50} height={50}/>
                        <div className={styles.descriptionHolder}>
                            <h3 className={styles.subtitle}>Минимальный инвентарь</h3> 
                            <p className={styles.text}> Минимальное пространство, LaproBoxMed и ПК - вы готовы к работе!</p>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}