import styles from './styles.module.scss';
import Instr1 from '../../images/Instr1.jpg';
import Instr2 from '../../images/Instr2.jpeg';
import Instr3 from '../../images/Instr3.jpg';
import Instr4 from '../../images/Instr4.jpeg';
import Instr5 from '../../images/Instr5.jpeg';
import Instr6 from '../../images/Instr6.png';
import { Product } from '../productComponent/product.jsx';

export const InstrumentsHolder = () => {
        const products = [
            {
                image: Instr1,
                title: 'Лапароскопический иглодержатель',
                text: '(5 х 330 мм)',
                price: '6499 р',
            },
            {
                image: Instr2,
                title: 'Лапароскопический диссектор ',
                text: '(5 х 330 мм)',
                price: '3599 р',
            },
            {
                image: Instr3,
                title: 'Лапароскопические ножницы',
                text: '(5 х 330 мм)',
                price: '3599 р',
            },
            {
                image: Instr4,
                title: 'Лапароскопический зажим',
                text: '(5 х 330 мм)',
                price: '3599 р',
            },
            {
                image: Instr5,
                title: 'Набор лапароскопических инструментов',
                text: '',
                price: '15999 р',
            },
            {
                image: Instr6,
                title: 'Лапароскоп тренировчный ',
                text: '',
                price: '4599 р',
            },
        ]
   
    return(
        <div className={styles.productsHolder}>
            <a href='/'><button className={styles.button}>На главную страницу</button></a>
            <h2 className={styles.title}>ЛАПАРОСКОПИЧЕСКИЕ ИНСТРУМЕНТЫ</h2>
            <div className={styles.products}>
                {products.map(product =>{

                    return  <Product product={product} />
                })
                }
            </div>
        </div>
    )
    
}