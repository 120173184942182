import styles from './styles.module.scss';
import { MapWrap } from '../map/map.jsx';
import { ContactIcons } from '../contactIcons/contactIcons.jsx';


export const Contacts = () => {
    return(
        <div className={styles.contacts} id='#contacts'>
            <div className={styles.links}>
                <p className={styles.title}> Контакты   </p>
                <p className={styles.text}> Мы будем рады обратной связи и вашим вопросам   </p>
                <div className={styles.detatils}>
                    <a href='mailto: osokinamary28@gmail.com' className={styles.link}>E-mail: osokinamary28@gmail.com</a> 
                    <a href='tel: +7 (963) 533 7037' className={styles.link}>Телефон: +7 (963) 533 7037 </a> 
                    <ContactIcons />
                </div>
               
            </div>
            <MapWrap />
        </div>
    )
}