function getCookie (name) {
  let cookieValue = '';
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim()
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
        break
      }
    }
  }
  return cookieValue
}
export function getCSRFToken () {
  return getCookie('csrftoken')
}

export function getCookie1() {
	console.log(document.cookie)
	fetch('/api/set-cookie/', {}).then(res => {
		const respJson = res.json()
		respJson.then(r => {
			document.cookie = `csrftoken1=${r.message}`
		})
	})
}

