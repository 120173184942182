/* eslint-disable no-restricted-globals */
import styles from './styles.module.scss';
import Logo from '../../images/laproLogo.png';
import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { BiSolidUserCircle } from "react-icons/bi";
import { ContactIcons } from '../contactIcons/contactIcons.jsx';
import Hamburger from 'hamburger-react';

export const Header = () => {
    const [isHeaderBig, setBigHeader] = useState(false);

    const [currentIndex, setCurrentIndex] = useState();
    const [coloredHeader, setColoredHeader] = useState(false);

    const menu = [
        {
            text: "О нас",
            link: "#about",
        },
        {
            text: "Продукты",
            link: "#products",
        },
        {
            text: "Приложение",
            link: "#application",
        },
        {
            text: "Контакты",
            link: "#contacts",
        },
      
    ]
    useEffect(() => {
        const listener = () => {
          if (window.scrollY > 140) {
            setColoredHeader(true);
          } else setColoredHeader(false);
        };
        window.addEventListener("scroll", listener);
    
        return () => {
          window.removeEventListener("scroll", listener);
        };
      }, []);
    
    return (
        <>
            
                <header className={classNames(styles.header, !coloredHeader ? '' : styles.headerDark, location.pathname !== '/' ? styles.headerAnotherPage : '')} >
                    <div className={styles.logoHolder}>
                        <img src={Logo} alt={'laprobox logo'} width={274} height={80}/>
                    </div>
                    <div className={styles.menuHolder}>
                        <div className={styles.menu}>
                            {
                                menu.map((item, index)=>{
                                    return(
                                        <div className={styles.item} key={index}>
                                            <a href={item.link} onClick={() => setCurrentIndex(index)} className={classNames(styles.itemText, currentIndex === index ? styles.itemTextActive : '')}>{item.text}</a>
                                        </div>
                                    )
                                    
                                })
                                
                            }
                            
                        </div>
                        <ContactIcons />
                        <a href='/login'>                        
                            <BiSolidUserCircle  className={styles.login} fill='#781f19' />
                        </a>
                    </div>                  
                </header>


                 <div className={styles.mobileHeader}>
                 <Hamburger toggled={isHeaderBig} onToggle={() => setBigHeader(!isHeaderBig)} color='black'/>
                        <div className={classNames(styles.small, `${isHeaderBig ? styles.big : ''}`)}>
                            <div className={styles.menuMobile}>
                                {
                                    menu.map((item, index)=>{
                                        return(
                                        
                                            <div className={styles.item} key={index}> 
                                                <a href={item.link} onClick={() => setCurrentIndex(index)} className={classNames(styles.itemText, currentIndex === index ? styles.itemTextActive : '')}>{item.text}</a>
                                                
                                            </div>
                                        )
                                        
                                    })
                                    
                                }
                                
                            </div>
                            <div className={styles.iconsContainer}>
                                 <ContactIcons />
                                <a href='/login'>                        
                                    <BiSolidUserCircle  className={styles.login} fill='#781f19' />
                                </a>
                            </div>
                           
                            <div className={styles.logoHolder}>
                                <img src={Logo} alt={'laprobox logo'} width={274} height={80}/>
                            </div>

                        </div>
                    </div>
        </>    
           
            
        
       
    )
}