/* eslint-disable no-restricted-globals */
import { IconButton } from "@mui/material";
/* import { useConfig } from "../../api/api.js"; */
/* import { redirectToProvider } from "../../api/api.js"; */
import { FaYandexInternational } from "react-icons/fa";
import styles from "./styles.module.scss";
import { getCSRFToken } from "../../api/django";
/* import {YaAuthSuggest} from '@y'; */

export default function Yand(props: { process: any }) {
  
  const req = async () =>{
    const requestOptions = {
      provider: 'yandex', process:"login", callback_url:"/account/provider/callback", csrfmiddlewaretoken: getCSRFToken() 
  };
    postForm('https://lbox.ssmu.ru/_allauth/browser/v1/auth/provider/redirect',requestOptions)
  } 

  function postForm (action:any, data:any) {
    const f = document.createElement('form')
    f.method = 'POST'
    f.action = action
  
    for (const key in data) {
      const d = document.createElement('input')
      d.type = 'hidden'
      d.name = key
      d.value = data[key]
      f.appendChild(d)
    }
    document.body.appendChild(f)
    f.submit()
  }

  return (
    <form method="post" action="{% /_allauth/browser/v1/auth/provider/redirect %}">
      <IconButton onClick={req}>
        <FaYandexInternational className={styles.icon} fill="red" />
      </IconButton>
    </form>
  );
}
