import styles from './styles.module.scss';
import { Person } from '../../../components/person/person.jsx';
import { LoginHeader } from '../../../components/loginHeader/loginHeader';


const PersonalData = () => {
    return(
        <>
        <LoginHeader/>
        <div className={styles.data}>
            <Person/>
            <form actions='/serverAdres' method='post' classNames={styles.container}>
                <div className={styles.input}>
                    <label for='name'>Имя</label>
                    <input type='text' id='name'/> 
                </div>
                <div className={styles.input}>
                    <label for='surname'>Фамилия</label>
                    <input type='text' id='surname'/> 
                </div>
                <div className={styles.input}>
                    <label for='birthday'>Дата рождения</label>
                    <input type='number' id='birthday' placeholder="день.месяц.год"/> 
                </div>
                <div className={styles.input}>
                    <label for='uni'>Университет</label>
                    <input type='text' id='uni'/> 
                </div>
            </form>
            
        </div> 
         </>
    )
}
export default PersonalData;