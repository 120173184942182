import styles from './styles.module.scss';
import { SlSocialVkontakte } from "react-icons/sl";
import Yand from './yandex';
import { RiGoogleLine } from "react-icons/ri";
import { IconButton } from "@mui/material";
import { getCSRFToken } from "../../api/django";

export const RegistrationIcons = () => {

    const req = async (provider) =>{
        const requestOptions = {
          provider: provider, process:"login", callback_url:`${provider === 'vk' ? "https://lbox.ssmu.ru/api/accounts/vk/login/callback/" : "/account/provider/callback"}`, csrfmiddlewaretoken: getCSRFToken() 
      };
        postForm('https://lbox.ssmu.ru/_allauth/browser/v1/auth/provider/redirect',requestOptions)
      } 

      function postForm (action, data) {
        const f = document.createElement('form')
        f.method = 'POST'
        f.action = action
      
        for (const key in data) {
          const d = document.createElement('input')
          d.type = 'hidden'
          d.name = key
          d.value = data[key]
          f.appendChild(d)
        }
        document.body.appendChild(f)
        f.submit()
      }

    return(
        <div className={styles.registrationIcons}>
            <p className={styles.text}>Войти через почту или соц сети</p>
            <div className={styles.iconsHolder}>
                <Yand />
                <IconButton method="post" onClick={()=>req('google')}>
                    <RiGoogleLine className={styles.icon} fill='red'/>
                </IconButton>
                <IconButton method="post" onClick={()=>req('vk')}>
                  <SlSocialVkontakte className={styles.icon} fill='red'/>
                </IconButton>
                {/* <IconButton method="post" onClick={()=>req('telegram')}>
                 tg
                </IconButton> */}
                
            </div>
        </div>
    )
}