import { Button } from "@mui/material";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";

export default function Confirm () {
  const navigate = useNavigate();
  const onClick=()=>{
    return navigate('/login')
  }
    return (
      <div className={styles.wrapper}>
        <div className={styles.textWrapper}>
          <h1 className={styles.title}>Подтвердите адрес электронной почты</h1>
          <p className={styles.text}>Пожалуйста, подтвердите свой адрес электронной почты.</p>
          <Button className={styles.btn} variant="outlined" onClick={()=>onClick()}>Понятно!</Button>
        </div>
      </div>
    )
  }