
import { FaChevronDown } from "react-icons/fa";
import styles from './styles.module.scss';
// import { motion } from "framer-motion"

export const MainPage = () => {
    return(
        <div className={styles.textHolder}>
            <h1 className={styles.mainTitle}>ЛАПАРОСКОПИЧЕСКОЕ ОБУЧЕНИЕ СТАЛО ДОСТУПНЫМ</h1>
            <h2 className={styles.additionalText}>ТРЕНИРОВОЧНЫЕ БОКСЫ, ИНСТРУМЕНТЫ И УПРАЖНЕНИЯ</h2>
            <FaChevronDown  className={styles.icon}/>
        </div>
    )
}