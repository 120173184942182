// 'use client';
import { LoginHeader } from "../../components/loginHeader/loginHeader.jsx";
import styles from "./styles.module.scss";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { createPortal } from "react-dom";
import { Popover } from "../../components/popover/popover.jsx";
import { BoxNumber } from "../../components/boxNumber/boxNumber.jsx";
import { Person } from "../../components/person/person.jsx";
import { useState, useEffect } from "react";
import { getSessions } from "../../api/allauth";
import { Navigate } from "react-router-dom";
import { URLs } from "../../api/routing";
import {getAuth} from "../../api/allauth.js"

const AuthorizedPage = () => {
  const [session, setSessions] = useState(undefined);
  const [error, setError] = useState(false);
  const [userinfo, setUserinfo] = useState(false);

  

  useEffect(() => {
    // eslint-disable-next-line no-undef
    getAuth().then(data => setUserinfo(data)).catch((e) => {
      console.error(e)
    })
    getSessions().then((resp) => {
      if (!!resp.data.length || resp.status === 200) {
        // eslint-disable-next-line no-undef
        setSessions(resp.data);
        setError(false);
      } else {
        setError(true);
      }
    });
  }, []);

  const [popoverInVisible, setPopoverInVisible] = useState(false);

  const style = {
    py: 0,
    width: "100%",
    maxWidth: 360,
    borderRadius: 2,
    border: "1px solid",
    borderColor: "divider",
    backgroundColor: "background.paper",
  };

  if (error) {
    return <Navigate to={URLs.LOGIN_URL} />;
  }

  return (
    <>
      <LoginHeader authed={true} user={userinfo} />
      <div className={styles.authPage}>
        <Person />
        <div>
          {createPortal(
            <Popover isInVisible={popoverInVisible}>
              <BoxNumber
                onClose={() => setPopoverInVisible(true)}
                isInVisible={popoverInVisible}
              />
            </Popover>,
            document.body
          )}
          <List sx={style}>
            <ListItem>
              <ListItemText primary="БАЗОВЫЙ КУРС" />
            </ListItem>
            <Divider component="li" />
            <ListItem>
              <ListItemText primary="Учебное видео 1" />
            </ListItem>
            <Divider component="li" />
            <ListItem>
              <ListItemText primary="Учебное видео 2" />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
              <ListItemText primary="Учебное видео 3" />
            </ListItem>
            <Divider variant="middle" component="li" />
            <ListItem>
              <ListItemText primary="Учебное видео 4" />
            </ListItem>
          </List>
        </div>
        <div className={styles.content}>Материалы для обучения</div>
      </div>
    </>
  );
};
export default AuthorizedPage;
