import images from './cloud-error.svg'

import './ErrorPage.scss';

const ErrorPage = () => {
  return (
    <div className={'errorPage'}>
      <div>Извините, кажется, что-то пошло не так.</div>
      <div>Попробуйте позже.</div>

      <img width={500} height={500} src={images}/>
      <div className='footer'>Если ошибка всё ещё сохраняется долгое время, пожалуйста сообщеите на почту: <a href='mailto:belyakov.gy@ssmu.ru'>belyakov.gy@ssmu.ru</a></div>
    </div>
  );
};

export default ErrorPage;
