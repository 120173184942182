import { About } from "../../components/about/about";
import { Application } from "../../components/application/application";
import { Contacts } from "../../components/contacts/contacts";
import { Footer } from "../../components/footer/footer";
import { Header } from "../../components/header/header";
import { MainPage } from "../../components/mainPage/mainPage";
import { Photos } from "../../components/photos/photos";
import { Products } from "../../components/products/products";
import styles from "./page.module.css";


export default function Home() {
  return (
    <main >
      {/* <Header/> */}
      <div className={styles.main}>
        <Header/>
        <MainPage/>
      </div>
      
            
      <About />
      <Products />
      <Application />
      <Photos />
      <Contacts/>
      <Footer/>
    </main>
  );
}